/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import API from '../../Api';
import PropTypes from 'prop-types';
import logo from '../../assets/icons/logo.svg';
import './login.css';
import { Buffer } from "buffer";
import { NotificationManager } from "react-notifications";
import {decode, encode} from 'base-64';

export default class Login extends Component {
  constructor(props) {
    super(props);
    sessionStorage.clear();
    this.state = {
      email: '',
      password: '',
      client_key: "",
      errors: {},
      isLoggedIn: false,
      isDisabled: false,
      otpLayer: false,
      userName: '',
      mailCode: '',
      errorText: '',
      isChecked: false
    }
  }

  // function to handle input value change
  handleChange = (event) => {
    const { errors } = this.state;
    errors[event.target.name] = '';
    this.setState({ [event.target.name]: event.target.value, errors });
  }

  // function to handle form validation
  isFormValid = () => {
    const { errors } = this.state;
    let isValid = true;
    if (!this.state.email) {
      isValid = false;
      errors.email = "*Please enter a valid email";
    }
    if (!this.state.password) {
      isValid = false;
      errors.password = "*Please enter password";
    }
    if (!this.state.client_key) {
      isValid = false;
      errors.client_key = "*Please enter client key";
    }
    this.setState({ errors });
    return isValid;
  }

  /**
   * function to handle validation while click on forgot password
   */
  ifForgotPassFormValid = () => {
    const { errors } = this.state;
    let isValid = true;
    if (
      !this.state.email ||
      !/^[+\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.state.email)
    ) {
      isValid = false;
      errors.email = "*Please enter a valid email";
    } if (!this.state.client_key) {
      isValid = false;
      errors.client_key = "*Please enter client key";
    }
    this.setState({ errors });
    return isValid;
  };

  // function to clear form
  clearForm = () => {
    this.setState({ email: '', password: '' });
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onSignIn();
    }
  }
  _handleOnEnter = (e) => {
      if (e.key === 'Enter') {
          this.onLogin();
      }
  }

  /**
   * Function to handle get user details just after the login
   */
  getUserByEmail = async () => {
    let getUser = {
      email: this.state.email,
      key: this.state.client_key,
    };
    await API.post("getUserByEmail", getUser).then((userDetails) => {
      sessionStorage.setItem(
        "userDetail",
        JSON.stringify(userDetails.data.data)
      );
    });
  };

  // function to handle login
  onSignIn = async () => {
    if(this.state.isChecked && this.state.email !== ''){
      localStorage.clientkey = this.state.client_key
      localStorage.username = this.state.email
      localStorage.password = encode(this.state.password)
      localStorage.remember = this.state.isChecked
    }else{
      localStorage.removeItem("clientkey");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("remember");
    }
    if (this.isFormValid()) {
      const data = {
        email: this.state.email,
        password: this.state.password,
        key: this.state.client_key,
      };
      await this.getUserByEmail();
      API.post("login", data)
        .then((response) => {
          if (response.data.code === 401) {
            // NotificationManager.error(
            //   `${response.data.message}`,
            //   "Failed",
            //   3001
            // );
            this.setState({errorText: '*Incorrect email or password'})
          }
          if (response.data.status === 200) {
            this.setState({
              otpLayer: true,
              userName: response.data.first_name,
            })
            sessionStorage.setItem("clientDetail", JSON.stringify(response.data.clientDetail))
          }
          if (response.data.token) {
            sessionStorage.setItem("token", JSON.stringify(response.data));
            this.setState({ isLoggedIn: true }, async () => {
              await this.getUserByEmail()
            });
          }
          return response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  /**
   * function to handle forgot password link and send OTP to registered Email id
   */
  handleForgotPassowrd = () => {
    const { email, client_key } = this.state;
    let body = {
      email: email,
      key: client_key,
    };
    if (this.ifForgotPassFormValid()) {
      API.post("forgotPassword", body)
        .then((res) => {
          if (res.data.status === 200) {
            NotificationManager.success(`${res.data.message}`, "Successfully!");
            this.props.history.push({
              pathname: "/forgot-password",
              state: { email: email, client_key: client_key, first_name: res.data.first_name },
            });
          } else {
            NotificationManager.error(`${res.data.message}`, "Failed", 3001);
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  componentDidMount() {
    if (window.location.href?.split("?")[1]?.split("=")[0] === "session") {
      let sessionToken = window.location.href?.split("?")[1]?.split("=")[1];
      let json = Buffer.from(sessionToken, "base64").toString();
      let jsonObject = JSON.parse(json);
      this.setState({
        client_key: jsonObject.clientKey,
        email: jsonObject.email,
        isDisabled: true,
      });
    }
    if(localStorage.remember && localStorage.username !== ''){
      this.setState({
        isChecked: true,
        client_key: localStorage.clientkey,
        email: localStorage.username,
        password: decode(localStorage.password)
      })
    }
  }

  // handleChange = (e) => {
  //   const { name, value } = e.target;
  // };

  onLogin = (e) => {
    const { email, client_key, mailCode } = this.state;
    let body = {
      email: email,
      key: client_key,
      OTP: mailCode
    }
    API.post("otpVerifyLogin", body).then((res) => {
      if (res.data.status === 200) {
        sessionStorage.setItem("token", JSON.stringify(res.data));
        this.setState({ isLoggedIn: true });
        NotificationManager.success("OTP verified successfully", "Successfully!", 3000)
      } else {
        NotificationManager.error("Incorrect OTP", "Failed", 3001)
      }
    })
  }

  // function to implement remember me
  rememberMe = (e) => {
    this.setState({isChecked: e.target.checked});
  }

  render() {
    if (sessionStorage.getItem("token")) {
      this.props.history.push("/dashboard");
    }
    const { email, password, errors, client_key, isDisabled, isChecked } = this.state;
    const style = {
      textAlign: 'left',
      height: 0,
      lineHeight: '16px'
    }

    const inputStyle = {
      width: '400px',
      height: '40px',
      borderRadius: '5px 5px 5px 3px',
      border: '1px solid #C4C4C4'
    }
    const buttonStyle = {
      backgroundColor: '#10496a',
      color: 'white',
      width: '400px'
    }
    return (
      <>
        {this.state.otpLayer === true ?
          <section className="page-backGround">
            <div className="d-flex justify-content-center align-items-center h-100">
              <Card variant="outlined" className="loginCard h100" onKeyDown={this._handleOnEnter}>
                <CardContent>
                  <div className="row customize-dflex">
                    <div className="col-6 col-sm-12 col-lg-6 col-md-12 d-flex align-items-center">
                      <img src={logo} alt="logo" width={420} height={155} />
                    </div>
                    <div className="col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align">
                      <CardHeader
                        title={
                          <span className="font-face-poppins pageHeadings">
                            Let’s Verify Your Account
                          </span>
                        }
                      />
                      <div>
                        <span className="">Welcome {this.state.userName}!</span>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="mailCode">
                          What is the code that was emailed to you?
                        </label>
                        <input
                          className="inputFields"
                          name="mailCode"
                          value={this.state.mailCode}
                          onChange={(e) => this.handleChange(e)}
                        />
                        <p className="text-danger" style={style}>
                          {errors.mailCode}
                        </p>
                      </div>

                      <div className="mt-3">
                        <button className="changePswrd" onClick={this.onLogin}>
                          <span>Verify</span>
                        </button>
                      </div>

                      <div className="termsCondition mt-2">
                        <p>
                          By logging in you agree to the
                          <a
                            href="/"
                            style={{ textDecoration: "none", color: "#81C3D7" }}
                          >
                            {" "}
                            terms{" "}
                          </a>{" "}
                          and{" "}
                          <a
                            href="/"
                            style={{ textDecoration: "none", color: "#81C3D7" }}
                          >
                            conditions
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </section>
          :
          <section style={{ backgroundColor: "#10496a", height: "100vh" }}>
            <div className="d-flex justify-content-center align-items-center h-100">
              <Card
                variant="outlined"
                className="loginCard h100"
                onKeyDown={this._handleKeyDown}
              >
                <CardContent>
                  <div className="row customize-dflex">
                    <div className="col-6 col-sm-12 col-lg-6 col-md-12 d-flex align-items-center">
                      <img src={logo} alt="logo" width={420} height={155} />
                    </div>
                    <div className="col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align">
                      <CardHeader
                        title={
                          <span
                            className="font-face-poppins"
                            style={{
                              fontWeight: 400,
                              color: "#414141",
                              fontSize: 24,
                              marginLeft: -15,
                            }}
                          >
                            Account Registration
                          </span>
                        }
                      />
                      <div>
                        <label htmlFor="clientKey" style={{ fontSize: 16 }}>
                          Client Key
                        </label>
                        <br />
                        <input
                          fullWidth
                          className="p-2"
                          type="input"
                          name="client_key"
                          value={client_key}
                          style={inputStyle}
                          disabled={isDisabled}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger" style={style}>
                          {errors.client_key}
                        </p>
                      </div>
                      <div>
                        <label htmlFor="email" style={{ fontSize: 16 }}>
                          Email Address
                        </label>
                        <br />
                        <input
                          fullWidth
                          className="p-2"
                          type="email"
                          name="email"
                          value={email}
                          style={inputStyle}
                          disabled={isDisabled}
                          onChange={this.handleChange}
                        />
                        <p className="text-danger" style={style}>
                          {errors.email}
                        </p>
                      </div>

                      <div>
                        <label htmlFor="password" style={{ fontSize: 16 }}>
                          Password
                        </label>
                        <br />
                        <input
                          fullWidth
                          className="p-2"
                          type="password"
                          name="password"
                          value={password}
                          style={inputStyle}
                          onChange={this.handleChange}
                        />
                        <span className="text-danger" style={style}>
                          {errors.password}
                        </span>
                        <span className="text-danger" style={style}>
                          {this.state.errorText}
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-6 col-sm-6 col-lg-6 d-flex align-items-center justify-content-start">
                          <input
                            type="checkbox"
                            style={{
                              width: 20,
                              height: 20,
                              border: "1px solid #C4C4C4",
                              borderRadius: "4px 4px 4px 3px",
                            }}
                            checked={isChecked}
                            onChange={this.rememberMe}
                          />
                          <span className="ms-2">Remember Me</span>
                        </div>
                        <div
                          className="col-6 col-sm-6 col-lg-6"
                          style={{ textAlign: "right", paddingRight: 32 }}
                        >
                          <p>
                            <a
                              onClick={this.handleForgotPassowrd}
                              style={{ cursor: "pointer", textDecoration: "none", color: "#81C3D7" }}
                            >
                              Forgot Password?
                            </a>
                          </p>
                        </div>
                      </div>

                      <div className="mt-4 pb-2">
                        <Button
                          fullWidth
                          variant="contained"
                          style={buttonStyle}
                          onClick={() => this.onSignIn()}
                        >
                          Log In
                        </Button>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-12 mt-2">
                        <p>
                          <span style={{ color: "#414141" }}>
                            By logging in you agree to the{" "}
                          </span>
                          <a
                            href="#"
                            style={{ textDecoration: "none", color: "#81C3D7" }}
                          >
                            terms{" "}
                          </a>
                          <span style={{ color: "#414141" }}>and </span>
                          <a
                            href="#"
                            style={{ textDecoration: "none", color: "#81C3D7" }}
                          >
                            conditions
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </section>
        }
      </>
    )
  }
}
Login.propTypes = {
  history: PropTypes.array.isRequired
}