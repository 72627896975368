import React, { Component } from 'react';
import SideBar from '../Sidebar';
import { Card, CardContent, CardHeader, Button, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import { advantagePlanSelectType } from '../Inspection-NotRan/data';
import NoViolationDatatable from './datatable';
import '../Inspection-NotRan/inspection.css';
import { NotificationManager } from 'react-notifications';
import API from '../../Api';
import LogModal from './log_modal';
import { userActivity } from '../../activity/userActivity';
import _ from 'lodash';
import bug from '../../assets/icons/bug.svg';
import { socketInstance } from '../../socket/connection';

class InspectionNoViolation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bugModal: false,
            isOther: false,
            selectValue: '',
            rules: [],
            bugRuleId: '',
            bugRuleName: '',
            errorDescription: '',
            showEligibilityResponse: false,
            showPatientDetails: true,
            reviewChecked: localStorage.getItem('isReviewd'), // props.location.state.trizettoData[0].isReviewed,
            viewedDetail: sessionStorage.getItem('viewedDetail'),
            token: JSON.parse(sessionStorage.getItem("token")).token,
            advantagePlanModal: false,
            advantagePlans: [],
            searchKey: 'contractNumber',
            searchValue: this.props.location.state.eligibilityData[0].contractNumber ? this.props.location.state.eligibilityData[0].contractNumber : '',
            bugModal: false,
            isOther: false,
            selectValue: '',
            rules: [],
            RPA_response_data: null,
            finalResult: this.props.location.state.eligibilityData[0]?.isPassed === true
        }
    }

    // function to toggle eligibility response visibility
    handleToggle = () => {
        this.setState({
            showEligibilityResponse: !this.state.showEligibilityResponse,
            showPatientDetails: !this.state.showPatientDetails
        })
    }
    onClose = () => {
        this.setState({
            showPatientDetails: true,
            showEligibilityResponse: false
        });
    }

    // function to handle advantage plan modal
    openAdvantagePlanModal = () => {
        this.setState({ advantagePlanModal: true }, () => {
            this.searchAdvantagePlans(this.state.searchKey, this.state.searchValue);
            // this.getAdvantagePlans();
        })
    }
    closeAdvantagePlanModal = () => {
        this.setState({ advantagePlanModal: false, searchKey: '', searchValue: this.props.location.state.eligibilityData[0].contractNumber, advantagePlans: [] })
    }

    // function to get advantage plan list
    getAdvantagePlans = () => {
        API.get('getAdvantagePlanList').then((response) => {
            let advantagePlanList = response.data.data;
            this.setState({ advantagePlans: advantagePlanList })
        }).catch(err => {
            console.error(err)
        })
    }

    // logic to search advantage plans by dropdown selection
    handleSelectChange = e => {
        let value = advantagePlanSelectType.filter(item => {
            return item.key === e.target.value
        })
        // if (this.state.searchValue !== '') {
        //     this.searchAdvantagePlans();
        // }
        this.setState({
            searchKey: value ? value[0].key : 'contractNumber',
            // searchValue: ''
        });
    }

    handleInputChange = (event) => {
        this.setState({ searchValue: event.target.value }, () => {
            this.searchAdvantagePlans(this.state.searchKey ? this.state.searchKey : 'contractNumber', this.state.searchValue);
            // this.searchAdvantagePlans(this.state.searchKey ? this.state.searchKey : 'contractNumber', this.state.searchValue ? this.state.searchValue : this.state.contractNumber);
        });
    }

    searchAdvantagePlans = _.debounce(() => {
        this.setState({ rules: [] });
        API.get('searchInAdvantagePlanList', { params: { [this.state.searchKey ? this.state.searchKey : 'contractNumber']: this.state.searchValue } }).then((response) => {
            // API.get('searchInAdvantagePlanList', { params: { [this.state.searchKey ? this.state.searchKey : 'contractNumber']: this.state.searchValue ? this.state.searchValue : this.state.contractNumber } }).then((response) => {
            // console.log(response.data);
            let advantagePlans = response.data.data;
            this.setState({ advantagePlans: advantagePlans });
        }).catch(err => {
            console.error(err);
        })
    }, 1000);

    // function to redirect to insurance page
    linkEdi = (id, linkedStatus) => {
        // console.log(this.props.location.state.chargeData);
        let chargeData = this.props.location.state.allChargeData;

        const linkedData = chargeData.filter(f => f.EncounterID === id).map(item => {
            const obj = {}
            obj["pName"] = item.payerName;
            obj["pId"] = item.payerId;
            obj["linkedPayer"] = item.PrimaryInsurancePlanName;
            obj["linkedPayerId"] = item.PrimaryInsurancePolicyPlanID;

            return obj;
        })
        if (!linkedData.length) return;
        const { PrimaryInsurancePlanName, PrimaryInsurancePolicyPlanID } = chargeData[0]
        // console.log("linkedData", chargeData)
        this.props.history.push({
            pathname: `/insurances/${id}/${PrimaryInsurancePolicyPlanID}/${localStorage.getItem("isPrimary") === "true" ? "Primary" : "Secondary"}`,
            state: {
                chargeData: chargeData,
                linkedData: linkedData,
                insuranceType: localStorage.getItem("isPrimary") === "true" ? "Primary" : "Secondary",
                PrimaryInsurancePlanName: PrimaryInsurancePlanName
            }
        });
    }

    // function to handle edit rule
    handleEdit = (id) => {
        let processedRules = this.props.location.state.trizettoData;
        // console.log('processedRules', processedRules);
        let violatedRules = processedRules.filter(item => item.ruleSuccess === false);
        // console.log('violatedRules', violatedRules);
        let editViolatedRule = violatedRules.filter(item => item.ruleId === id);
        // console.log('editViolatedRule', editViolatedRule);
        let length = editViolatedRule[0].encounterRuleResult.ruleData.ruleInfo.length;
        let obj = {
            for: editViolatedRule[0].encounterRuleResult.ruleData.ruleInfo[length - 1].operator,
            pendValue: editViolatedRule[0].encounterRuleResult.ruleData.result,
            reason: editViolatedRule[0].encounterRuleResult.ruleData.Reason,
            operator: "none"
        }
        editViolatedRule[0].encounterRuleResult.ruleData.ruleInfo.push(obj);
        this.props.history.push({
            pathname: `/edit-rule/${id}`,
            state: {
                editRule: true,
                ruleDetail: editViolatedRule[0].encounterRuleResult.ruleData,
                tags: editViolatedRule[0].encounterRuleResult.tags,
                ruleId: id,
                path: `/edit-rule/${id}`
            }
        });

    }

    // Function to add our give data into cache
    addDataIntoCache = (cacheName, url, response) => {
        const data = new Response(JSON.stringify(response));
        if ('caches' in window) {
            caches.open(cacheName).then((cache) => {
                cache.put(url, data);
            });
        }
    }

    updateReviewStatus = (isReviewed, EncounterID) => {
        return new Promise((resolve, reject) => {
            API.post('updateReviewStatus', { isReviewed, EncounterID }).then((res) => {
                if (res.data.code === 200 && res.data.success === true) {
                    resolve(res)
                }
            }).catch((error) => {
                reject(false)
            })
        })
    }

    handleBugModal = (rule) => {
        this.setState({
            bugModal: !this.state.bugModal,
            logModalOpen: false,
            bugRuleId: rule.ruleId,
            bugRuleName: rule?.encounterRuleResult?.ruleName
        });
    }

    // getRules = (practiceType = false) => {
    //     API.get(
    //         "getAllBugRules" + "?practiceId=0" 
    //         )
    //         .then((response) => {
    //             let rules = response.data.data;
    //             this.setState({
    //                 rules: rules ? rules : [],
    //             });
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // };

    handleSelectOption = (e) => {
        const { value } = e.target;
        if (value == 'other') {
            this.setState({ isOther: true });
        } else {
            this.setState({ isOther: false });
        }
        this.setState({ selectValue: value });
    }

    falseBugReport = () => {
        try {
            let rule = this.props.location.state.trizettoData;
            let bugRule = rule.filter(item => item.ruleId === this.state.bugRuleId);

            let body_data = {
                encounterId: bugRule[0].EncounterID,
                ruleId: this.state.bugRuleId,
                errorDescription: this.state.errorDescription
            }
            API.post('bugReportByFalseViolation', body_data).then((res) => {
                console.log(res.data);
                NotificationManager.success("Reported Bug Successfully", 'Success', 3000);
                this.setState({
                    bugModal: false,
                    logModalOpen: false,
                    bugRuleId: '',
                    bugRuleName: '',
                    errorDescription: ''
                });
            })
        } catch (err) {
            console.error(err);
        }
    }

    componentDidMount() {
        (async () => {
            if (this.state.viewedDetail === '0') {
                var activity = new userActivity('Viewed Detail ', this.state.token)
                await activity.setActivityAPI({ encounterID: this.props.location.state.trizettoData[0].EncounterID });
                sessionStorage.setItem('viewedDetail', '1')
            }
        })()
        let socket = socketInstance()
        if (socket) {
            socket.emit('checkRPAStatus', {
                practiceId: this.props.location.state.chargeData.PracticeID,
                encounterId: this.props.location.state.trizettoData[0].EncounterID,
                instanceID: this.props.location.state.trizettoData[0].instanceID,
            });
            socket.on('checkRPAStatus', (data) => {
                console.log(data)
                if (data.status === false) {
                    return;
                } else {
                    if (data.data[0]) {
                        this.setState({
                            RPA_response_data: data.data[0]
                        })
                        if (data.data[0].rpaStatus === 'received and success') {
                            if ('encounterStatus' in data) {
                                if (data.encounterStatus === 'Approved') {
                                    this.setState({
                                        reviewChecked: 'true',
                                        finalResult: true
                                    })
                                    localStorage.setItem('isReviewd', 'true')
                                    return;
                                }
                                if (data.encounterStatus === false) {
                                    let multiPracticeRules = this.props.location.state.trizettoData.filter(item => item.practiceId === '0');
                                    let practiceRules = this.props.location.state.trizettoData.length - multiPracticeRules.length;
                                    socket.emit('checkRPAStatus', {
                                        practiceId: this.props.location.state.chargeData.PracticeID,
                                        encounterId: this.props.location.state.trizettoData[0].EncounterID,
                                        instanceID: this.props.location.state.trizettoData[0].instanceID,
                                        ruleViolationCount: practiceRules - (this.state.RPA_response_data?.rpaResponse?.ruleFixed ? this.state.RPA_response_data?.rpaResponse?.ruleFixed : 0)
                                    });
                                }
                            }
                        }
                    }
                }
                socket.emit('checkRuleViolationStatus', {
                    practiceId: this.props.location.state.chargeData.PracticeID,
                    encounterId: this.props.location.state.trizettoData[0].EncounterID,
                    instanceID: this.props.location.state.trizettoData[0].instanceID,
                });
            })
            socket.on('checkRuleViolationStatus', (data) => {
                if (data.stopGoBack == true) {
                    this.props.history.goBack()
                }
            });
        }

        // this.getRules()
    }

    render() {
        const { showEligibilityResponse, showPatientDetails, advantagePlanModal, advantagePlans, rules, bugModal, isOther, selectValue } = this.state;
        const { state } = this.props.location;
        // const data = state.chargeData.length > 1 ? state.chargeData.slice(0, 1) : state.chargeData;this.props.location.state.eligibilityData.[0]?.isPassed === true
        const data = state.chargeData;
        let last_log = state?.rulesRanBy[0];
        // console.log(data)
        const status = state.trizettoData;
        const elStatus = state.eligibilityData;
        const ruleRunData = state.trizettoData[0]
        // let eligibilityResponse = status.data;
        // console.log(ruleRunData);
        let ranDate = new Date(elStatus[0]?.currentTime).toLocaleDateString('en-US');
        console.log(status);

        let date = new Date(elStatus[0]?.currentTime);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let ranTime = hours + ':' + minutes + ' ' + ampm;

        let practiceData = JSON.parse(sessionStorage.getItem("practice"));


        let multiPracticeRules = status.filter(item => item.practiceId === '0');
        let practiceRules = status.length - multiPracticeRules.length;

        let isRPA = status.filter(item => item?.encounterRuleResult?.result === 'Run RPA Flow');

        const cardStyle = {
            border: '1px solid #D8D8D8',
            borderRadius: '9px',
            width: '95%',
            textAlign: 'left'
        }
        const headerStyle = {
            borderBottom: '1px solid darkgrey',
            height: 40
        }
        const heading = {
            fontSize: '20px'
        }
        const textStyle = { textDecoration: 'none', color: '#00708C', cursor: 'pointer' }

        let num = 0;

        let rejectReason = elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.inforeceiver && elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection ?
            <span>{elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection[0]?.rejectreason[0]}</span> :
            elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.dependent && elStatus[0]?.eligibilityData?.dependent[0]?.rejection ?
                <span>{elStatus[0]?.eligibilityData?.dependent[0]?.rejection[0]?.rejectreason[0]}</span> :
                elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.subscriber && elStatus[0]?.eligibilityData?.subscriber[0]?.rejection ?
                    <span>{elStatus[0]?.eligibilityData?.subscriber[0]?.rejection[0]?.rejectreason[0]}</span> :
                    elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData["soap:Envelope"] &&
                        elStatus[0]?.eligibilityData["soap:Envelope"]["soap:Body"] &&
                        elStatus[0]?.eligibilityData["soap:Envelope"]["soap:Body"][0]?.DoInquiryResponse[0]?.DoInquiryResult[0]?.SuccessCode[0] === 'PayerNotSupported' ?
                        // <span>{"Enrollment Pending"}</span> :'Unable to Check'
                        <span>{"Payer Not Supported"}</span> :
                        (elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData["soap:Envelope"] &&
                            elStatus[0]?.eligibilityData["soap:Envelope"]["soap:Body"] &&
                            elStatus[0]?.eligibilityData["soap:Envelope"]["soap:Body"][0]?.DoInquiryResponse[0]?.DoInquiryResult[0]?.SuccessCode[0] === 'ValidationFailure' ||
                            elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData["soap:Envelope"] &&
                            elStatus[0]?.eligibilityData["soap:Envelope"]["soap:Body"] &&
                            elStatus[0]?.eligibilityData["soap:Envelope"]["soap:Body"][0]?.DoInquiryResponse[0]?.DoInquiryResult[0]?.SuccessCode[0] === 'ProviderNotOnFile') ?
                            <span>{"Group NPI Not on File"}</span> : 'Unable to Check'

        console.log(rejectReason)

        return (
            <section style={{ width: '99%', position: 'relative', marginTop: '75px', height: "calc(100vh - 100px)", background: 'white' }}>
                <SideBar />
                <div className='subheader row'>
                    <div className='subheader-controls col-md-4'>
                        <button className='subheader-controls-close'
                            onClick={() => { this.props.history.goBack() }}>
                            <span className='subheader_controls_text'>
                                CLOSE
                            </span>
                        </button>
                    </div>
                    <div className='subheader-heading col-md-4'>
                        <h5 className="mt-3 text-center text-white font-face-futura">
                            {this.state.finalResult === true ? 'THERE WERE NO VIOLATIONS FOR THIS ENCOUNTER' : 'ONE OR MORE RULES HAVE BEEN VIOLATED'}
                        </h5>
                    </div>
                    <div className='mt-2 subheader-review-btn col-md-4' style={{ textAlign: 'right', paddingRight: '68px' }}>
                        <button
                            className='subheader_review_btn'
                            onClick={async () => {
                                const response = await this.updateReviewStatus(this.state.reviewChecked.toString() === 'true' ? false : true, ruleRunData.EncounterID);
                                if (response) {
                                    NotificationManager.success('Updated successfully', 'Success', 2000)
                                    this.setState({
                                        reviewChecked: response.data.data
                                    })
                                    localStorage.setItem('isReviewd', response.data.data)
                                    var activity = new userActivity(this.state.reviewChecked.toString() === 'true' ? 'Mark as review' : 'Marked as Not Reviewed', this.state.token)
                                    let activityResponse = await activity.setActivityAPI({ encounterID: this.props.location.state.trizettoData[0].EncounterID });
                                    if (!activityResponse.success)
                                        NotificationManager.error(activityResponse.message, 'Error!', 2000)
                                } else {
                                    NotificationManager.error('Data cannot be updated', 'Error!', 2000)
                                }
                            }}>
                            <div class="round-check">
                                {
                                    String(this.state.reviewChecked) == 'true' ? <input type="checkbox" id="check-round" checked={true} /> : <input type="checkbox" id="check-round" checked={false} />
                                }
                                <span className='checkbox_label' ></span>
                                <span className='review_text text-dark dlock'>Mark as Reviewed </span>
                            </div>
                        </button>
                        <LogModal encounterID={this.props.location.state.trizettoData[0].EncounterID} />
                    </div>
                </div>

                <div className="row mrt-355" style={{ marginLeft: 10 }}>
                    <div className="col-lg-12 col-sm-12">
                        {showEligibilityResponse === true && showPatientDetails === false && <span
                            style={{ cursor: 'pointer', float: "right", marginRight: 75, color: 'black' }} onClick={this.onClose}>X
                            {/* <CloseIcon onClick={this.onClose} /> */}
                            <br />
                            <small style={{ color: 'blue' }}>Print</small>
                        </span>}
                    </div>

                    <div className="col-lg-6 col-sm-12 mt-4">
                        <div className="mrl-35 scroll-patient"
                            style={{ overflowY: (status.length > 0 || elStatus[0].isDuplicate === true) ? 'scroll' : 'hidden', overflowX: 'hidden' }}>
                            <div style={{ marginLeft: 60, width: '92%' }}>
                                <><h5 className="pt-2 px-2 font-face-futura cardHead" style={{ textAlign: 'left' }}>Insurance</h5>
                                    <Card className='cardStyle' style={cardStyle}>
                                        <CardContent>
                                            <div className="pt-2 font-face-futura">
                                                <p><strong style={heading}>Case Name:</strong><br /><span className='cardText'>{data.CaseName}</span></p>
                                                <p><strong style={heading}>Primary Insurance:</strong><br />
                                                    <p className='cardText'>{data.PrimaryInsurancePlanName}</p>
                                                    <p className='mx-2 cardText2'>Policy:&nbsp;<span>{data.PrimaryInsurancePolicyNumber}</span></p>
                                                    <p className='mx-2 cardText'>Eligibility Status:&nbsp;
                                                        <span style={{ textDecoration: 'none', color: elStatus[0]?.eligibilitySuccess === true ? '#5CB02C' : '#954437' }}>
                                                            {this.props.location.state.isBypassed === true ? 'Bypassed' : data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === true ? 'Active' :
                                                                // (insurance.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false) ? 'Unable to check' : 
                                                                (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.rejectedReason === 'Non Citizens') ? 'Non Citizen' :
                                                                    (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.rejectedReason === 'NO PART B') ? 'No Part B Benefits' :
                                                                        (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.rejectedReason === 'Contact Following Entity') ? 'Medicare Advantage Plan' :
                                                                            (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.rejectedReason === 'MCO') ? 'Advantage Plan' :
                                                                                (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.isPolicyNumberPresent === false) ? 'Unable to check' : rejectReason}
                                                            {/* {insurance.PrimaryInsurancePlanName && status[idx].eligibilitySuccess === true ? 'Active' : (insurance.PrimaryInsurancePlanName && insurance.PrimaryInsuranceLinkedStatus === true && status[idx].eligibilitySuccess === false) ? 'Not Active' : (insurance.PrimaryInsurancePlanName && insurance.PrimaryInsuranceLinkedStatus !== true) ? 'Unable to check' : ''} */}
                                                        </span><br />
                                                        {/* {elStatus[idx].runEligibilityForPrimary === false && insurance.PrimaryInsuranceLinkedStatus === true ?
                                                                <p
                                                                    style={{ textDecoration: 'none', color: 'teal', cursor: 'pointer' }}
                                                                    onClick={(id) => { this.linkEdi(insurance.EncounterID, insurance.PrimaryInsuranceLinkedStatus); localStorage.setItem("isPrimary", true) }}>Edit EDI</p> :
                                                                    elStatus[idx].runEligibilityForPrimary === false && insurance.PrimaryInsuranceLinkedStatus === false ? <p
                                                                    style={{ textDecoration: 'none', color: 'teal', cursor: 'pointer' }}
                                                                    onClick={(id) => { this.linkEdi(insurance.EncounterID, insurance.PrimaryInsuranceLinkedStatus); localStorage.setItem("isPrimary", true) }}>Link EDI</p> : ""
                                                            } */}
                                                        {elStatus[0]?.runEligibilityForPrimary === true ?
                                                            <>{elStatus[0]?.eligibilitySuccess === true ? '' : (elStatus[0]?.eligibilitySuccess === false && data.PrimaryInsuranceLinkedStatus === true && elStatus[0]?.rejectedReason === '') ?
                                                                <p style={textStyle}
                                                                    onClick={(id) => { this.linkEdi(data.EncounterID, data.PrimaryInsuranceLinkedStatus); localStorage.setItem("isPrimary", true) }}>Edit EDI</p> :

                                                                (elStatus[0]?.eligibilitySuccess === false && data.PrimaryInsuranceLinkedStatus === false) ?
                                                                    <p style={textStyle}
                                                                        onClick={(id) => { this.linkEdi(data.EncounterID, data.PrimaryInsuranceLinkedStatus); localStorage.setItem("isPrimary", true) }}>Link EDI</p> :

                                                                    ((elStatus[0]?.eligibilitySuccess === false) && (data.PrimaryInsuranceLinkedStatus === true) && (elStatus[0]?.rejectedReason === 'Contact Following Entity')) ?
                                                                        <p style={textStyle}
                                                                            onClick={this.openAdvantagePlanModal}>View Advantage Plan Directory</p> : ''}
                                                            </> :
                                                            <>{data.PrimaryInsuranceLinkedStatus === true ?
                                                                <p style={textStyle}
                                                                    onClick={(id) => { this.linkEdi(data.EncounterID, data.PrimaryInsuranceLinkedStatus); localStorage.setItem("isPrimary", true) }}>Edit EDI</p> :
                                                                <p style={textStyle}
                                                                    onClick={(id) => { this.linkEdi(data.EncounterID, data.PrimaryInsuranceLinkedStatus); localStorage.setItem("isPrimary", true) }}>Link EDI</p>}
                                                            </>}
                                                    </p>
                                                    {/* {elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.inforeceiver && elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection &&
                                                        <p className='mx-2 cardText'>Rejection Reason:&nbsp;
                                                            <span>{elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection[0]?.rejectreason[0]}</span>
                                                        </p>
                                                    } */}

                                                    {/* {elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.inforeceiver && elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection ?
                                                        <p className='mt-2 cardText2'>Rejection Reason:&nbsp;
                                                            <span>{elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection[0]?.rejectreason[0]}</span>
                                                        </p> :
                                                        elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.dependent && elStatus[0]?.eligibilityData?.dependent[0]?.rejection ?
                                                            <p className='mt-2 cardText2'>Rejection Reason:&nbsp;
                                                                <span>{elStatus[0]?.eligibilityData?.dependent[0]?.rejection[0]?.rejectreason[0]}</span>
                                                            </p> :
                                                            elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.subscriber && elStatus[0]?.eligibilityData?.subscriber[0]?.rejection ?
                                                                <p className='mt-2 cardText2'>Rejection Reason:&nbsp;
                                                                    <span>{elStatus[0]?.eligibilityData?.subscriber[0]?.rejection[0]?.rejectreason[0]}</span>
                                                                </p> : ''} */}
                                                </p>

                                                {data.SecondaryInsurancePlanName ? <>
                                                    <p><strong>Secondary Insurance:</strong><br />
                                                        <>{data.SecondaryInsurancePlanName}</><br />
                                                        <>Policy:&nbsp;<span>{data.SecondaryInsurancePolicyNumber}</span></><br />
                                                        {/* <>Eligibility Status:&nbsp;&nbsp;
                                                            <a href="/insurances" style={{textDecoration: 'none', color: status.eligibilitySuccess === true ? '#5CB02C' : '#954437'}}>
                                                                {insurance.SecondaryInsurancePlanName && status.eligibilitySuccess === true ? 'Active' : (insurance.SecondaryInsurancePlanName && insurance.SecondaryInsuranceLinkedStatus === true && status.eligibilitySuccess === false) ? 'Not Active' : (insurance.SecondaryInsurancePlanName && insurance.SecondaryInsuranceLinkedStatus !== true) ? 'Unable to check' : ''}
                                                            </a><br />
                                                            {status.isPassed !== true && insurance.SecondaryInsuranceLinkedStatus === true ? 
                                                            <p style={{textDecoration: 'none', color: 'teal', cursor: 'pointer'}}>Edit EDI</p> : 
                                                            <p style={{textDecoration: 'none', color: 'teal', cursor: 'pointer'}}>Link EDI</p>}
                                                        </> */}
                                                    </p>
                                                </> : ''}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </>
                            </div>
                            {advantagePlanModal && <Dialog fullWidth
                                maxWidth="lg" className='dialogHeight'
                                open={advantagePlanModal}
                                // onClose={this.closeAdvantagePlanModal}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description">
                                <div align="center" className='planTitle'>
                                    <DialogTitle id="alert-dialog-title">
                                        <span className='font-face-futura'>{"Advantage Plan Directory"}</span>
                                    </DialogTitle>
                                </div>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <div align="center" className='font-face-futura planDesc'>This list comes from Medicare and helps you identify which advantage plan the patient has.</div>
                                    </DialogContentText>
                                    <div className='mt-2'>
                                        <Card className='planCard'>
                                            <CardHeader className='planCardHead' title={<h5 className="text-white font-face-futura">Search</h5>} />
                                            <CardContent className='planContent'>
                                                <div className="row">
                                                    <div className='col-lg-6 col-sm-6'>
                                                        <label htmlFor="group" className='cardLabel'>Search Type:</label>
                                                        <select fullWidth className='px-2 text' onChange={this.handleSelectChange}>
                                                            {advantagePlanSelectType.map((plan, key) => (
                                                                <option key={key} value={plan.key}>{plan.value}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-6 col-sm-6'>
                                                        <label htmlFor="group" className='cardLabel'>Search:</label><br />
                                                        <input type='text' className="p-2 text"
                                                            value={this.state.searchValue}
                                                            onChange={this.handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <Card className="mt-2 planCard">
                                            <CardHeader className='planCardHead'
                                                title={<h5 className="text-white font-face-futura">Advantage Plans</h5>} />
                                            <CardContent className='planContent planContentHeight'>
                                                <div className='table-responsive tableManager font-face-futura'>
                                                    <table className='table managerTable'>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center text-white">Legal Entity Name</th>
                                                                <th className="text-center text-white">Marketing Name</th>
                                                                <th className="text-center text-white">Contract Number</th>
                                                                {/* <th className="text-center text-white">Effective Date</th> */}
                                                                <th className="text-center text-white">Contact Number</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {advantagePlans && advantagePlans.map((plan) => (
                                                                <tr>
                                                                    <td className="text-center py-2 px-0 font15">{plan.legalEntityName}</td>
                                                                    <td className="text-center py-2 px-0 font15">{plan.marketingName}</td>
                                                                    <td className="text-center py-2 px-0 font15">{plan.contractNumber}</td>
                                                                    {/* <td className="text-center py-2 px-0 font15">{plan.effectiveDate}</td> */}
                                                                    <td className="text-center py-2 px-0 font15">{plan.contactNumber}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <footer>
                                            <div className='actionButton'>
                                                <Button fullWidth
                                                    className="mt-4 mb-2 mx-2 looksgood"
                                                    variant="outlined" onClick={this.closeAdvantagePlanModal}>
                                                    <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Thanks!</span>
                                                </Button>
                                            </div>
                                        </footer>
                                    </div>
                                </DialogContent>
                            </Dialog>}

                            <div style={{ marginLeft: 60, width: '92%' }}>
                                <><h5 className="pt-3 px-2 font-face-futura cardHead" style={{ textAlign: 'left' }}>Processing Info</h5>
                                    <Card className='cardStyle' style={cardStyle}>
                                        <CardContent>
                                            <div className='row'>
                                                <div className="col-6 col-sm-6 col-lg-6 font-face-futura">
                                                    <p className='phead'><b>Rules Ran On</b><br /><span className='ptitle'>{new Date(status[0].currentTime).toLocaleString('en-US')}</span></p>
                                                </div>
                                                <div className="col-6 col-sm-6 col-lg-6 font-face-futura">
                                                    <p className='phead'><b>Rules Ran By</b><br />
                                                        <span className='ptitle'>{last_log?.first_name !== null ? last_log?.first_name + " " + last_log?.last_name : "System (Auto-Run)"}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-6 col-sm-6 col-lg-6 font-face-futura">
                                                    <p>
                                                        <span className='phead'><b>Multi-Practice Rules</b></span><br />
                                                        <span className='spanTitle'>Total Active Rules:{' '}
                                                            {status[0].rulesVoilated === false ? status[0].multiPracticeRuleCount : status[0]?.encounterRuleResult?.multiPracticeRuleCount}
                                                        </span><br />
                                                        <span className='spanTitle'>Rules Passed:{' '}
                                                            {status[0].rulesVoilated === false ? status[0].multiPracticeRuleCount : status[0]?.encounterRuleResult?.multiPracticeRuleCount - multiPracticeRules.length}
                                                        </span><br />
                                                        <span className='spanTitle'>Rules Violated:{' '}{status[0].rulesVoilated === false ? 0 : multiPracticeRules.length}</span><br />
                                                        {isRPA?.length > 0 && <span className='spanTitle'>Rules Fixed:{'0 '}{ }</span>}
                                                    </p>
                                                </div>
                                                <div className="col-6 col-sm-6 col-lg-6 font-face-futura">
                                                    <p>
                                                        <span className='phead'><b>Practice Rules</b></span><br />
                                                        <span className='spanTitle'>Total Active Rules:{' '}{status[0].rulesVoilated === false ? status[0].practiceRuleCount : status[0]?.encounterRuleResult?.practiceRuleCount}</span><br />
                                                        <span className='spanTitle'>Rules Passed:{' '}{status[0].rulesVoilated === false ? status[0].practiceRuleCount : status[0]?.encounterRuleResult?.practiceRuleCount - practiceRules}</span><br />
                                                        <span className='spanTitle'>Rules Violated:{' '}{status[0].rulesVoilated === false ? 0 : practiceRules - (this.state.RPA_response_data?.rpaResponse?.ruleFixed ? this.state.RPA_response_data?.rpaResponse?.ruleFixed : 0)}</span><br />
                                                        {isRPA?.length > 0 && <span className='spanTitle'>Rules Fixed: {this.state.RPA_response_data?.rpaResponse?.ruleFixed}</span>}
                                                    </p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </>
                            </div>

                            {/* {data.map((item, idx) => (item.instanceID === status[idx].instanceID) && status[idx].encounterRuleResult.map((result) => { */}
                            {elStatus[0].isDuplicate === true &&
                                <div className="mt-2 mb-2 font-face-futura" style={{ marginLeft: 60, width: '92%' }}>
                                    <h5 className="pt-4 px-2 cardHead" style={{ textAlign: 'left' }}>Rule Violation ({'1'})</h5>
                                    <Card className='cardStyle' style={cardStyle}>
                                        <CardContent>
                                            <div className='row font-face-futura'>
                                                <div className='col-lg-12 col-sm-12' style={{ fontSize: 18 }}>
                                                    <span><b>Rule Name:</b></span>
                                                    <p>INTERNAL RULE - Duplicate DOS</p>

                                                    <span><b>Rule:</b></span>
                                                    <p>We have flagged this encounter because there was another date of service for this patient with a
                                                        different encounter ID. Check Kareo to ensure that this is not caused by a data entry error.
                                                    </p>

                                                    <span><b>Created By:</b></span>
                                                    <p>INTERNAL RULE</p>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            }
                            {status?.map((result) => {
                                // if (result.ruleSuccess === false) num++;
                                if (result?.ruleSuccess === false && elStatus[0].isDuplicate === false) {
                                    num++;
                                } else if (result?.ruleSuccess === false && elStatus[0].isDuplicate === true) {
                                    num = num + 2;
                                };
                                const createdDate = result?.encounterRuleResult?.ruleData?.created_at;
                                const modifiedDate = result?.encounterRuleResult?.ruleData?.updated_at;

                                let tiles = result?.encounterRuleResult?.ruleData?.ruleInfo?.filter(item => item.procedureTile);

                                let globalPeriodRule = result?.encounterRuleResult?.ruleData?.ruleInfo?.filter(item => item.category === 'Global Period');
                                console.log(globalPeriodRule)

                                var months = new Array(12);
                                months[0] = "January";
                                months[1] = "February";
                                months[2] = "March";
                                months[3] = "April";
                                months[4] = "May";
                                months[5] = "June";
                                months[6] = "July";
                                months[7] = "August";
                                months[8] = "September";
                                months[9] = "October";
                                months[10] = "November";
                                months[11] = "December";

                                let created_date = new Date(createdDate);
                                let created_month_value = created_date.getMonth();
                                let created_day_value = created_date.getDate();
                                let created_year_value = created_date.getFullYear();
                                let created_date_value = months[created_month_value] + " " + created_day_value + ", " + created_year_value;

                                let created_hours = created_date.getHours();
                                let created_minutes = created_date.getMinutes();
                                let created_ampm = created_hours >= 12 ? 'PM' : 'AM';
                                created_hours = created_hours % 12;
                                created_hours = created_hours ? created_hours : 12; // the hour '0' should be '12'
                                created_minutes = created_minutes < 10 ? '0' + created_minutes : created_minutes;
                                let createdTime = created_hours + ':' + created_minutes + ' ' + created_ampm;

                                let modified_date = new Date(modifiedDate);
                                let modified_month_value = modified_date.getMonth();
                                let modified_day_value = modified_date.getDate();
                                let modified_year_value = modified_date.getFullYear();
                                let modified_date_value = months[modified_month_value] + " " + modified_day_value + ", " + modified_year_value;

                                let modified_hours = modified_date.getHours();
                                let modified_minutes = modified_date.getMinutes();
                                let modified_ampm = modified_hours >= 12 ? 'PM' : 'AM';
                                modified_hours = modified_hours % 12;
                                modified_hours = modified_hours ? modified_hours : 12; // the hour '0' should be '12'
                                modified_minutes = modified_minutes < 10 ? '0' + modified_minutes : modified_minutes;
                                let modifiedTime = modified_hours + ':' + modified_minutes + ' ' + modified_ampm;

                                // return result.success === false && (
                                return result?.ruleSuccess === false && (
                                    <div className="mt-2 mb-2 font-face-futura" style={{ marginLeft: 60, width: '92%' }}>
                                        <h5 className="pt-4 px-2 cardHead" style={{ textAlign: 'left' }}>Rule Violation ({num})</h5>
                                        <Card className='cardStyle' style={cardStyle}>
                                            <CardContent>
                                                <div className='bug_img' onClick={() => this.handleBugModal(result)}>
                                                    <img src={bug} alt='bug' />
                                                </div>
                                                <div className='row font-face-futura'>
                                                    <div className='col-6' style={{ fontSize: 18 }}>
                                                        <span><b>Rule Name:</b></span>
                                                        {/* <p>{result.ruleName}</p> */}
                                                        <p>{result?.encounterRuleResult?.ruleName}</p>

                                                        <span><b>Practice Name:</b></span>
                                                        <p>{result?.practiceId === '0' ? 'Multi Practice Rule' : practiceData?.practiceName}</p>

                                                        <span><b>Rule:</b></span>
                                                        <p>When the {' '}
                                                            {result?.encounterRuleResult?.ruleData?.ruleInfo?.map((item, idx) => {
                                                                let error = "{{error}}";
                                                                let procedureTileValue;
                                                                let finalValues;
                                                                let finalValues1;
                                                                let procedureValues;

                                                                switch (item.category) {
                                                                    case 'Diagnosis 1':
                                                                    case 'Diagnosis 2':
                                                                    case 'Diagnosis 3':
                                                                    case 'Diagnosis 4':
                                                                    case 'Diagnosis in any slot':
                                                                    case 'Modifier':
                                                                    case 'Units':
                                                                    case 'Unit Charge':
                                                                    case 'Total Charges':
                                                                        procedureTileValue = item.procedureTile === 'any' ? 'for any procedure' : `for procedure tile ${item.procedureTile}`
                                                                        break;
                                                                    default:
                                                                        procedureTileValue = ""
                                                                        break;
                                                                }
                                                                switch (item.category) {
                                                                    case 'Diagnosis 1':
                                                                    case 'Diagnosis 2':
                                                                    case 'Diagnosis 3':
                                                                    case 'Diagnosis 4':
                                                                    case 'Diagnosis in any slot':
                                                                    case 'Procedure Code':
                                                                        finalValues = (item.condition === 'is' || item.condition === 'contains') ? (item.values.map(val => val.name ? val.name.split('-')[0].trim() : val)).join(' or ') : (item.values.map(val => val.name ? val.name.split('-')[0].trim() : val)).join(' and ');
                                                                        break;
                                                                    default:
                                                                        finalValues = (item.condition === 'is' || item.condition === 'contains') ? (item.values.map(val => val.name ? val.name : val)).join(' or ') : (item.values.map(val => val.name ? val.name : val)).join(' and ');
                                                                        break;
                                                                }
                                                                switch (item.category) {
                                                                    case 'Global Period':
                                                                        finalValues1 = (item.values.map(val => val.name ? val.name.split('-')[0].trim() : val)).join(' or ');
                                                                        procedureValues = (item.procedureArray.map(val => val.name ? val.name.split('-')[0].trim() : val)).join(' or ');
                                                                        break;
                                                                    default:
                                                                        break;
                                                                }

                                                                // finalValues = (item.condition === 'is' || item.condition === 'contains') ? (item.values.map(val => val.name ? val.name.split('-')[0].trim() : val)).join(' or ') : (item.values.map(val => val.name ? val.name.split('-')[0].trim() : val)).join(' and ');
                                                                // return (<>
                                                                //     <span>{(idx > 0 || tiles.length > 0 ? ' the ' + item.category + ' ' + procedureTileValue : item.category + ' ' + procedureTileValue) + ' ' + item.condition}</span>{' '}
                                                                //     <span style={{ color: (finalValues.length === 0 && item.condition !== 'is missing') ? '#954437' : '#535353' }}>{((finalValues && finalValues.length !== 0) || item.condition === 'is missing') ? finalValues : error}</span>{' '}
                                                                //     <span>{(item.operator).toLowerCase()}</span>
                                                                // </>)
                                                                return (<>
                                                                    {item.category === 'Global Period' ?
                                                                        <>
                                                                            <span>{item.category}</span>{' '}
                                                                            <span>for the procedure(s)</span>{' '}
                                                                            <span style={{ color: '#297200' }}>{finalValues1}</span>{' '}
                                                                            <span>is within</span>{' '}
                                                                            {item.days && (<span style={{ color: '#297200' }}>{item.days}{' '}</span>)}
                                                                            <span>{(item.days === '0' || item.days === '1') ? 'day' : 'days'},</span>{' '}
                                                                            <span>{item?.globalDrop}</span>{' '}
                                                                            <span>we cannot bill the procedure(s)</span>{' '}
                                                                            <span style={{ color: '#297200' }}>{procedureValues}{' '}</span>
                                                                        </> :
                                                                        <>
                                                                            <span>{(idx > 0 || tiles.length > 0 ? ' the ' + item.category + ' ' + procedureTileValue : item.category + ' ' + procedureTileValue) + ' ' + item.condition}</span>{' '}
                                                                            <span style={{ color: (finalValues.length === 0 && item.condition !== 'is missing') ? '#954437' : '#535353' }}>
                                                                                {((finalValues && finalValues.length !== 0) || item.condition === 'is missing') ? finalValues : error}
                                                                            </span>{' '}
                                                                        </>
                                                                    }
                                                                    <span>{(item.operator).toLowerCase()}</span>
                                                                </>)
                                                            })} {result?.encounterRuleResult?.result}.</p>

                                                        {globalPeriodRule.length > 0 &&
                                                            <>
                                                                <span><b>Bumping Against:</b></span>
                                                                <p>
                                                                    <span style={{ fontSize: 16 }}><b>Encounter:</b> {String(result?.encounterRuleResult?.bumpingEncounter).split(',').join(', ')}</span><br />
                                                                    <span style={{ fontSize: 16 }}><b>Patient Name:</b> {data.PatientName}</span><br />
                                                                    <span style={{ fontSize: 16 }}><b>Date of Service:</b> {String(result?.encounterRuleResult?.bumpingDOS).split(',').join(', ')}</span>
                                                                </p>
                                                                <span><b>Variables Violated:</b></span>
                                                                <p>
                                                                    <span style={{ fontSize: 16 }}><b>Procedures:</b> {String(result?.encounterRuleResult?.bumpingProcedureCodes).split(',').join(', ')}</span>
                                                                </p>
                                                            </>
                                                        }

                                                        {result?.encounterRuleResult?.result === 'pend to review tab' && <><span><b>Reason:</b></span>
                                                            <p>{result?.encounterRuleResult?.reason}</p></>}

                                                        {/* {result?.encounterRuleResult?.result === 'run rpa flow' && <> */}
                                                        {result?.encounterRuleResult?.result === 'Run RPA Flow' && <>
                                                            <span><b>Action:</b></span>
                                                            <p>
                                                                <span style={{ fontSize: 14 }}><b>Action Taken:</b> </span><br />
                                                                <span style={{ fontSize: 14 }}><b>RPA Flow Name:</b> </span><br />
                                                                <span style={{ fontSize: 14 }}><b>Verify Authorization Line Note:</b> </span><br />
                                                                <span style={{ fontSize: 14 }}><b>Operator:</b> </span><br />
                                                                <span style={{ fontSize: 14 }}><b>Auth Note Field:</b> </span><br />
                                                                <span style={{ fontSize: 14 }}><b>Add Batch Message if there is an error?:</b> </span><br />
                                                                <span style={{ fontSize: 14 }}><b>Batch Message:</b> </span><br />
                                                                <span style={{ fontSize: 14 }}><b>Add Error Note if there is an error?:</b> </span><br />
                                                                <span style={{ fontSize: 14 }}><b>Error Message:</b> </span>
                                                            </p>
                                                            {this.state.RPA_response_data?.rpaStatus === "sent to RPA" && <>
                                                                <span><b>Result:</b></span>
                                                                <p>
                                                                    <span style={{ fontSize: 14 }}><b>RPA Result: Running...</b> </span><br />
                                                                    <span style={{ fontSize: 14 }}><b>RPA Start: {new Date(this.state.RPA_response_data.createdAt).toLocaleString()}</b> </span><br />
                                                                </p>
                                                            </>
                                                            }
                                                            {this.state.RPA_response_data?.rpaStatus === "received but failed" &&
                                                                <>
                                                                    <span><b>Result:</b></span>
                                                                    <p>
                                                                        <span style={{ fontSize: 14 }}><b>RPA Result: </b>Failed</span><br />
                                                                        <span style={{ fontSize: 14 }}><b>Error Code:</b> </span><br />
                                                                        <span style={{ fontSize: 14 }}><b>Error Message: {JSON.stringify(this.state.RPA_response_data)}</b> </span><br />
                                                                        <span style={{ fontSize: 14 }}><b>RPA Start: </b>{new Date(this.state.RPA_response_data.createdAt).toLocaleString()}</span><br />
                                                                        <span style={{ fontSize: 14 }}><b>RPA End: </b>{new Date(this.state.RPA_response_data.updatedAt).toLocaleString()}</span>
                                                                    </p>
                                                                </>
                                                            }
                                                            {this.state.RPA_response_data?.rpaStatus === "received and success" &&
                                                                <>
                                                                    <span><b>Result:</b></span>
                                                                    <p>
                                                                        <span style={{ fontSize: 14 }}><b>RPA Result: </b>Successful</span><br />
                                                                        <span style={{ fontSize: 14 }}><b>RPA Start: </b>{new Date(this.state.RPA_response_data.createdAt).toLocaleString()}</span><br />
                                                                        <span style={{ fontSize: 14 }}><b>RPA End: </b>{new Date(this.state.RPA_response_data.updatedAt).toLocaleString()}</span>
                                                                    </p>
                                                                </>
                                                            }
                                                            {this.state.RPA_response_data?.rpaStatus === "error" &&
                                                                <>
                                                                    <span><b>Result:</b></span>
                                                                    <p>
                                                                        <span style={{ fontSize: 14 }}><b>RPA Result: </b>Error</span><br />
                                                                        <span style={{ fontSize: 14 }}><b>Error Code:</b> {this.state.RPA_response_data.rpaResponse.error?.code.split()}</span><br />
                                                                        <span style={{ fontSize: 14 }}><b>Error Message: </b> {this.state.RPA_response_data.rpaResponse?.error?.message.split(/\r?\n/)[0]}</span><br />
                                                                        <span style={{ fontSize: 14 }}><b>RPA Start: </b>{new Date(this.state.RPA_response_data.createdAt).toLocaleString()}</span><br />
                                                                        <span style={{ fontSize: 14 }}><b>RPA End: </b>{new Date(this.state.RPA_response_data.updatedAt).toLocaleString()}</span>
                                                                    </p>
                                                                </>
                                                            }
                                                        </>}

                                                        <span><b>Tags:</b></span>
                                                        <p>{(result?.encounterRuleResult?.tags).join(', ')}</p>
                                                    </div>
                                                    <div className='col-6' style={{ fontSize: 18 }}>
                                                        <span><b>Status:</b></span>
                                                        <p>{result?.encounterRuleResult?.ruleData?.status === true ? 'On' : 'Off'}</p>

                                                        <span><b>Created By:</b></span>
                                                        <p>{result?.createdBy}</p>

                                                        <span><b>Created On:</b></span>
                                                        <p>{created_date_value} &nbsp;{createdTime}</p>

                                                        <span><b>Modified On:</b></span>
                                                        <p>{modified_date_value} &nbsp;{modifiedTime}</p>

                                                        <span><b>Modified By:</b></span>
                                                        <p>{result?.encounterRuleResult?.ruleData?.updatedBy}</p>

                                                        <span><b>Version:</b></span>
                                                        <p>{result?.encounterRuleResult?.version}</p>
                                                        <p>{ }</p>
                                                    </div>
                                                </div>
                                                <div className='mb-1'>
                                                    <Button fullWidth
                                                        variant='contained' className='editBtn'
                                                        onClick={(id) => this.handleEdit(result?.ruleId)}><span className='font-face-futura'>Edit</span></Button>
                                                </div>
                                                {/* <div className='mb-3' style={{float: 'right'}}>
                                                    <Button 
                                                    variant='contained' 
                                                    style={{backgroundColor: '#10496a', color: 'white'}}
                                                    onClick={(id) => this.handleEdit(result.ruleId)}>Edit</Button>
                                                </div> */}
                                            </CardContent>
                                        </Card>
                                        <Dialog
                                            className='issue-popup-main'
                                            fullWidth={'lg'}
                                            maxWidth={'lg'}
                                            open={bugModal}
                                            onClose={() => this.handleBugModal(false)}
                                        >
                                            <DialogTitle>
                                                <span className='reportIssue'>Report Issue</span>
                                                <div style={{ borderBottom: '1px solid #5CB02C' }}></div>
                                            </DialogTitle>
                                            <DialogContent>
                                                <Card className='issueCard'>
                                                    <CardContent className='issueContent'>
                                                        <div className='mx-2 row'>
                                                            <div className='col-12 col-lg-12 col-sm-12'>
                                                                <label htmlFor='issue'><strong style={{ fontSize: '18px' }}>Please select the issue:</strong></label>
                                                                <select className='issue_select' name='issue' value={selectValue} onChange={this.handleSelectOption}>
                                                                    <option value="claim">Claim didn't violate</option>
                                                                    <option value="other">Other</option>
                                                                </select>
                                                            </div>
                                                            {isOther === false ? <div className='mt-3 col-12 col-lg-12 col-sm-12'>
                                                                <label htmlFor='rules'><strong style={{ fontSize: '18px' }}>Which rule(s) should have violated?</strong></label>
                                                                <select className='issue_select' value={rules}>
                                                                    <option value="">Select</option>
                                                                    {rules?.map((rule) => (
                                                                        <option key={rule.ruleId} value={rule.ruleName}>{rule.ruleName}</option>
                                                                    ))}
                                                                </select>
                                                            </div> : <></>}
                                                            <div className='mt-3 col-12 col-lg-12 col-sm-12'>
                                                                <label htmlFor='info'><strong style={{ fontSize: '18px' }}>Please give us more information:</strong></label>
                                                                <textarea className='px-2 py-2 free_text' placeholder='Type here...'></textarea>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </DialogContent>
                                            <footer>
                                                <div className='buttons'>
                                                    <button className='activity_close_log'
                                                        onClick={this.handleBugModal}>
                                                        <span className='font-face-futura'>Close</span>
                                                    </button>
                                                    <button className='activity_submit_log'>
                                                        <span className='font-face-futura'>Submit</span>
                                                    </button>
                                                </div>
                                            </footer>
                                        </Dialog>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12 mt-4 font-face-futura" style={{ paddingLeft: 20 }}>
                        {showPatientDetails &&
                            // <div style={{height: 600, overflowY: 'scroll', marginLeft: 50}}>
                            <div className="mrl-35 scroll-patient">
                                <><h5 className="pt-2 px-2 cardHead">Subscriber Details</h5>
                                    <Card className="mt-2 cardStyle" style={cardStyle}>
                                        <CardContent>
                                            <div className="pt-2">
                                                <p><strong style={heading}>Patient Name:</strong>&nbsp;<br /><span className='cardText'>{data.PatientName}</span></p>
                                                <p><strong style={heading}>Patient ID:</strong><br /><span className='cardText'>{data.PatientID}</span></p>
                                                <p><strong style={heading}>Gender:</strong>&nbsp;<br /><span className='cardText'>{data.Gender}</span></p>
                                                <p><strong style={heading}>Date of Birth:</strong>&nbsp;<br /><span className='cardText'>{data.PatientDateOfBirth}</span></p>
                                                <p><strong style={heading}>Patient Address:</strong>&nbsp;<br /><span className='cardText'>{data.AddressLine1 + ' ' + data.AddressLine2 + ' ' + data.City + ' ' + data.Country + ' ' + data.ZipCode}</span></p>
                                                <p><strong style={heading}>Patient Alert:</strong><br /><span className='cardText'>{data.AlertMessage}</span></p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </>
                                <br />
                                <><h5 className="pt-2 px-2 cardHead">Patient (Dependent) Details</h5>
                                    <Card className='cardStyle' style={cardStyle}>
                                        <CardContent>
                                            <div className="pt-2">
                                                <p><strong style={heading}>Patient Name:</strong>&nbsp;<br /><span className='cardText'>{data.PatientName}</span></p>
                                                <p><strong style={heading}>Gender:</strong>&nbsp;<br /><span className='cardText'>{data.Gender}</span></p>
                                                <p><strong style={heading}>Date of Birth:</strong>&nbsp;<br /><span className='cardText'>{data.PatientDateOfBirth}</span></p>
                                                <p><strong style={heading}>Patient Address:</strong>&nbsp;<br /><span className='cardText'>{data.AddressLine1 + ' ' + data.AddressLine2 + ' ' + data.City + ' ' + data.Country + ' ' + data.ZipCode}</span></p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </>

                                <><h5 className="pt-4 px-2 cardHead">Insurance</h5>
                                    <Card className='cardStyle' style={cardStyle}>
                                        <CardContent>
                                            <div className="pt-2">
                                                <p><strong style={heading}>Case Name:</strong><br /><span className='cardText'>{data.CaseName}</span></p>
                                                <p><strong style={heading}>Primary Insurance:</strong>
                                                    <p className='cardText'>{data.PrimaryInsurancePlanName}</p>
                                                    <p className='mx-2 cardText2'>Policy:&nbsp;<span>{data.PrimaryInsurancePolicyNumber}</span></p>
                                                    <p className='mx-2 cardText'>Eligibility Status:&nbsp;
                                                        <span style={{ color: elStatus[0]?.eligibilitySuccess === true ? '#5CB02C' : '#954437' }}>
                                                            {this.props.location.state.isBypassed === true ? 'Bypassed' : data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === true ? 'Active' :
                                                                // (insurance.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false) ? 'Unable to check' : 
                                                                (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.rejectedReason === 'Non Citizens') ? 'Non Citizen' :
                                                                    (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.rejectedReason === 'NO PART B') ? 'No Part B Benefits' :
                                                                        (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.rejectedReason === 'Contact Following Entity') ? 'Medicare Advantage Plan' :
                                                                            (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.rejectedReason === 'MCO') ? 'Advantage Plan' :
                                                                                (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false && elStatus[0]?.isPolicyNumberPresent === false) ? 'Unable to check' : rejectReason}
                                                            {/* {data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === true ? 'Active' : (data.PrimaryInsurancePlanName && elStatus[0]?.eligibilitySuccess === false) ? 'Unable to Check' : 'Not Active'} */}
                                                        </span><br />
                                                        {/* {elStatus[0]?.eligibilityData &&
                                                            (elStatus[0]?.eligibilityData?.inforeceiver || elStatus[0]?.eligibilityData?.dependent || elStatus[0]?.eligibilityData?.subscriber) &&
                                                            (elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection || elStatus[0]?.eligibilityData?.dependent[0]?.rejection || elStatus[0]?.eligibilityData?.subscriber[0]?.rejection) ?
                                                            <p className='mt-2'>Rejection Reason:
                                                                <span>{(elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection[0]?.rejectreason[0] || elStatus[0]?.eligibilityData?.dependent[0]?.rejection[0]?.rejectreason[0] || elStatus[0]?.eligibilityData?.subscriber[0]?.rejection[0]?.rejectreason[0])}</span>
                                                                <span>{elStatus[0]?.eligibilityData?.dependent[0]?.rejection[0]?.rejectreason[0]}</span>
                                                            </p> : (data.PrimaryInsuranceLinkedStatus === 'false' || elStatus[0]?.eligibilityData?.length == 0) ? <></> :
                                                                <p style={{ color: 'teal', cursor: 'pointer' }}
                                                                    onClick={this.handleToggle}>View Report</p>
                                                        } */}
                                                        {/* {elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.inforeceiver && elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection ?
                                                            <p className='mt-3 cardText2'>Rejection Reason:&nbsp;
                                                                <span>{elStatus[0]?.eligibilityData?.inforeceiver[0]?.rejection[0]?.rejectreason[0]}</span>
                                                            </p> :
                                                            elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.dependent && elStatus[0]?.eligibilityData?.dependent[0]?.rejection ?
                                                                <p className='mt-3 cardText2'>Rejection Reason:&nbsp;
                                                                    <span>{elStatus[0]?.eligibilityData?.dependent[0]?.rejection[0]?.rejectreason[0]}</span>
                                                                </p> :
                                                                elStatus[0]?.eligibilityData && elStatus[0]?.eligibilityData?.subscriber && elStatus[0]?.eligibilityData?.subscriber[0]?.rejection ?
                                                                    <p className='mt-3 cardText2'>Rejection Reason:&nbsp;
                                                                        <span>{elStatus[0]?.eligibilityData?.subscriber[0]?.rejection[0]?.rejectreason[0]}</span>
                                                                    </p> :  */}
                                                        {(data.PrimaryInsuranceLinkedStatus === 'false' ||
                                                            elStatus[0]?.eligibilityData?.length == 0 || rejectReason) ? <></> :
                                                            // !elStatus[0]?.eligibilityData?.subscriber || !elStatus[0]?.eligibilityData?.dependent || elStatus[0]?.eligibilityData["soap:Envelope"]) ? <></> :
                                                            <p style={{ color: 'teal', cursor: 'pointer' }}
                                                                onClick={this.handleToggle}>View Report</p>}
                                                    </p>
                                                </p>
                                                {data.SecondaryInsurancePlanName ? <>
                                                    <p><strong style={heading}>Secondary Insurance:</strong>
                                                        <p className='cardText'>{data.SecondaryInsurancePlanName}</p>
                                                    </p>
                                                    <p className='cardText'>Policy:&nbsp;<span className='cardText'>{data.SecondaryInsurancePolicyNumber}</span></p>
                                                    {/* <p>Eligibility Status:&nbsp;&nbsp;
                                                <span style={{color: status.eligibilitySuccess === true ? '#5CB02C' : '#954437'}}>
                                                    {data.SecondaryInsurancePlanName && status.eligibilitySuccess === true ? 'Active' : (data.SecondaryInsurancePlanName && data.SecondaryInsuranceLinkedStatus === true && status.eligibilitySuccess === false) ? 'Not Active' : (data.SecondaryInsurancePlanName && data.SecondaryInsuranceLinkedStatus !== true) ? 'N/A' : ''}
                                                </span><br />
                                                {data.SecondaryInsuranceLinkedStatus === true ? <p style={{color: 'teal', cursor: 'pointer'}}
                                                onClick={this.handleToggle}>View Report</p> : <></>}
                                            </p> */}
                                                </> : ''}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </>

                                <><h5 className="pt-4 px-2 cardHead">Encounter</h5>
                                    <Card className='cardStyle' style={cardStyle}>
                                        <CardContent>
                                            <div className="pt-2">
                                                <p><strong style={heading}>Encounter ID:</strong><br /><span className='cardText'>{data.EncounterID}</span></p>
                                                <p><strong style={heading}>Created Date:</strong><br /><span className='cardText'>{new Date(data.CreatedDate).toLocaleDateString('en-US')}</span></p>
                                                <p><strong style={heading}>Post Date:</strong><br /><span className='cardText'>{data.PostingDate}</span></p>
                                                <p><strong style={heading}>Scheduling Provider:</strong><br /><span className='cardText'>{data.SchedulingProviderName}</span></p>
                                                <p><strong style={heading}>Rendering Provider:</strong><br /><span className='cardText'>{data.RenderingProviderName}</span></p>
                                                <p><strong style={heading}>Place of Service:</strong><br /><span className='cardText'>{data.ServiceLocationName}</span></p>
                                                <p><strong style={heading}>Place of Service Code:</strong><br /><span className='cardText'>{data.ServiceLocationPlaceOfServiceCode}</span></p>
                                                <p><strong style={heading}>Hospitalization Start Date:</strong><br /><span className='cardText'>{data.HospitalizationStartDate}</span></p>
                                                <p><strong style={heading}>Referring Provider:</strong><br /><span className='cardText'>{data.ReferringProviderName}</span></p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </>

                                {/* {data.map((auth) => (
                            <Card className="mt-3" variant="outlined" style={cardStyle}>
                                <CardHeader 
                                style={headerStyle} 
                                title={<h5 className="pt-2" style={{fontWeight: 'bold'}}>Authorization</h5>} />
                                <CardContent>
                                    <div className="pt-2">
                                        <p><strong>Authorization Number:</strong>&nbsp;{auth.Authorization1Number}</p>
                                        <p><strong>Auth Start:</strong>&nbsp;{auth.Authorization1StartDate}</p>
                                        <p><strong>Number of Visits:</strong>&nbsp;{auth.Authorization1NumberOfVisits}</p>
                                        <p><strong>Auth End:</strong>&nbsp;{auth.Authorization1EndDate}</p>
                                        <p><strong>Number of Visits(Used):</strong>&nbsp;{auth.Authorization1NumberOfVisitsUsed}</p>
                                    </div>
                                </CardContent>
                            </Card>
                            ))} */}
                                <><h5 className="pt-4 px-2 cardHead">Workers Compensation</h5>
                                    <Card className="mb-4 cardStyle" style={cardStyle}>
                                        <CardContent>
                                            <div className="pt-2">
                                                <p><strong style={heading}>DOI Start:</strong><br /><span className='cardText'>{data.DefaultCaseDatesInjuryStartDate}</span></p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </>
                            </div>}



                        {showEligibilityResponse &&
                            <div className="mt-2" style={{ height: 550, overflowY: 'scroll', marginLeft: 50 }}>
                                <Card variant="outlined" style={cardStyle}>
                                    <CardHeader
                                        style={headerStyle}
                                        title={<h5 className="pt-2" style={{ fontWeight: 'bold' }}>Eligibility Details</h5>} />
                                    <CardContent>
                                        <div className="pt-2">
                                            <p><strong>Payer Name:</strong>&nbsp;{elStatus[0]?.eligibilityData?.infosource[0].payername[0]}</p>
                                            <p><strong>Payer ID:</strong>&nbsp;{elStatus[0]?.eligibilityData?.infosource[0].payerid[0]}</p>
                                            <p><strong>Transaction ID:</strong>&nbsp;{elStatus[0]?.eligibilityData?.infosource[0].transactionid[0]}</p>
                                            <p><strong>Kareo Plan Name:</strong>&nbsp;{data.PrimaryInsurancePlanName}</p>
                                            <p><strong>Kareo Plan ID:</strong>&nbsp;{data.PrimaryInsurancePolicyPlanID}</p>
                                            <p><strong>Ran on:</strong>&nbsp;{ranDate} at {ranTime}</p>
                                        </div>
                                    </CardContent>
                                </Card>
                                <br />
                                <Card variant="outlined" style={cardStyle}>
                                    <CardHeader
                                        style={headerStyle}
                                        title={<h5 className="pt-2" style={{ fontWeight: 'bold' }}>Claim Details</h5>} />
                                    <CardContent>
                                        <div className="pt-2">
                                            <p><strong>Provider Name:</strong>&nbsp;{elStatus[0]?.eligibilityData?.inforeceiver[0].providername ? elStatus[0]?.eligibilityData?.inforeceiver[0].providername[0].last[0] : ''}</p>
                                            <p><strong>Provider NPI:</strong>&nbsp;{elStatus[0]?.eligibilityData.inforeceiver[0].npi[0]}</p>
                                            <p><strong>Patient Name:</strong>&nbsp;
                                                {elStatus[0]?.eligibilityData?.subscriber[0].patientname ? elStatus[0]?.eligibilityData?.subscriber[0].patientname[0].first[0] + ' ' + elStatus[0]?.eligibilityData?.subscriber[0].patientname[0].last[0] :
                                                    elStatus[0]?.eligibilityData?.dependent ? elStatus[0]?.eligibilityData?.dependent[0].patientname[0].first[0] + ' ' + elStatus[0]?.eligibilityData?.dependent[0].patientname[0].last[0] : ''}
                                            </p>
                                            {/* <p><strong>Patient Name:</strong>&nbsp;
                                            {!status.eligibilityData.dependent && status.eligibilityData.subscriber ? status.eligibilityData.subscriber[0].patientname[0].first[0] + ' ' + status.eligibilityData.subscriber[0].patientname[0].last[0] : 
                                            status.eligibilityData.dependent[0].patientname[0].first[0] + ' ' + status.eligibilityData.dependent[0].patientname[0].last[0]}
                                            </p> */}
                                            <p><strong>Patient Address:</strong>&nbsp;{data.AddressLine1 + ' ' + data.AddressLine2 + ' ' + data.City + ' ' + data.Country + ' ' + data.ZipCode}</p>
                                            <p><strong>Patient ID:</strong>&nbsp;{elStatus[0]?.eligibilityData?.subscriber[0].patientid ? elStatus[0]?.eligibilityData?.subscriber[0].patientid[0] : ''}</p>
                                            {elStatus[0]?.eligibilityData?.subscriber[0].sex || elStatus[0]?.eligibilityData?.dependent[0].sex ? <p><strong>Sex:</strong>&nbsp;
                                                {!elStatus[0]?.eligibilityData?.dependent && elStatus[0]?.eligibilityData?.subscriber ? elStatus[0]?.eligibilityData?.subscriber[0].sex[0] : elStatus[0]?.eligibilityData?.dependent[0].sex[0]}
                                            </p> : ''}
                                            <p><strong>Date of Birth:</strong>&nbsp;
                                                {!elStatus[0]?.eligibilityData?.dependent && elStatus[0]?.eligibilityData?.subscriber ? elStatus[0]?.eligibilityData?.subscriber[0]['date-of-birth'][0] : elStatus[0]?.eligibilityData?.dependent[0]['date-of-birth'][0]}
                                            </p>
                                            <p><strong>Date of service:</strong>&nbsp;
                                                {elStatus[0]?.eligibilityData?.subscriber && elStatus[0]?.eligibilityData?.subscriber[0].date ? elStatus[0]?.eligibilityData?.subscriber[0].date[0]['date-of-service'][0] : ''}
                                                {elStatus[0]?.eligibilityData?.dependent && elStatus[0]?.eligibilityData?.dependent[0].date ? elStatus[0]?.eligibilityData?.dependent[0].date[0]['date-of-service'][0] : ''}
                                            </p>
                                            {/* <p><strong>Date of service:</strong>&nbsp;
                                            {!status.eligibilityData.dependent && status.eligibilityData.subscriber && status.eligibilityData.subscriber[0].date ? status.eligibilityData.subscriber[0].date[0]['date-of-service'][0] : 
                                            status.eligibilityData.dependent[0].date[0]['date-of-service'][0]}
                                            </p> */}
                                        </div>
                                    </CardContent>
                                </Card>
                                <br />
                                <Card variant="outlined" style={cardStyle}>
                                    <CardHeader
                                        style={headerStyle}
                                        title={<h5 className="pt-2" style={{ fontWeight: 'bold' }}>Eligibility Overview</h5>} />
                                    <CardContent>
                                        <div className="pt-2">
                                            {/* {elStatus.eligibilitySuccess === true && elStatus[0]?.eligibilityData.subscriber && elStatus[0]?.eligibilityData.subscriber[0].benefit.map((item, 0) => { */}
                                            {elStatus[0]?.eligibilityData?.subscriber[0]?.benefit.map((item, idx) => {
                                                let length = elStatus[0]?.eligibilityData?.subscriber[0].benefit.length;
                                                // console.log(item.info);
                                                return (
                                                    <>
                                                        <p><strong>Info:</strong>&nbsp;{item.info ? item.info[0] : ''}</p>
                                                        {item.coveragelevel ? <p><strong>Coverage Level:</strong>&nbsp;{item.coveragelevel ? item.coveragelevel[0] : ''}</p> : ''}
                                                        {item.servicetype ? <p><strong>Service Type:</strong>&nbsp;{item.servicetype ? item.servicetype[0] : ''}</p> : ''}
                                                        {item.servicetypecode ? <p><strong>Service Type Code:</strong>&nbsp;{item.servicetypecode ? item.servicetypecode[0] : ''}</p> : ''}
                                                        {item.insurancetype ? <p><strong>Insurance Type:</strong>&nbsp;{item.insurancetype ? item.insurancetype[0] : ''}</p> : ''}
                                                        {item.insurancetypecode ? <p><strong>Insurance Type Code:</strong>&nbsp;{item.insurancetypecode ? item.insurancetypecode[0] : ''}</p> : ''}
                                                        {item.plancoveragedescription ? <p><strong>Plan Coverage Description:</strong>&nbsp;{item.plancoveragedescription ? item.plancoveragedescription[0] : ''}</p> : ''}
                                                        <p><strong>Date of Birth:</strong>&nbsp;{elStatus[0]?.eligibilityData?.subscriber[0]['date-of-birth'] ? elStatus[0]?.eligibilityData.subscriber[0]['date-of-birth'][0] : ''}</p>
                                                        <p><strong>Date Qualifier:</strong>&nbsp;{elStatus[0]?.eligibilityData?.subscriber[0].date && elStatus[0]?.eligibilityData?.subscriber[0]?.date[0]?.datequalifier[0]
                                                            + ', ' + elStatus[0]?.eligibilityData?.subscriber[0]?.date && elStatus[0]?.eligibilityData?.subscriber[0]?.date[1]?.datequalifier[0]}</p>
                                                        <p><strong>Date of service:</strong>&nbsp;{elStatus[0]?.eligibilityData?.subscriber[0]?.date && elStatus[0]?.eligibilityData?.subscriber[0]?.date[0] && elStatus[0]?.eligibilityData?.subscriber[0]?.date[0]['date-of-service'][0]
                                                            + '-' + elStatus[0]?.eligibilityData?.subscriber[0]?.date && elStatus[0]?.eligibilityData?.subscriber[0]?.date[1] && elStatus[0]?.eligibilityData?.subscriber[0]?.date[1]['date-of-service'][0]}</p>
                                                        {item.message ? <p><strong>Message:</strong>&nbsp;{item.message ? item.message : ''}</p> : ''}

                                                        {idx !== length - 1 ? <hr /> : <></>}
                                                    </>)
                                            })
                                            }
                                            {elStatus?.eligibilitySuccess === true && elStatus[0]?.eligibilityData?.dependent && elStatus[0]?.eligibilityData?.dependent[0].benefit.map((item, idx) => {
                                                let length = elStatus[0]?.eligibilityData?.dependent[0]?.benefit?.length;
                                                // console.log(length);
                                                return (
                                                    <>
                                                        <p><strong>Info:</strong>&nbsp;{item.info ? item.info[0] : ''}</p>
                                                        {item.coveragelevel ? <p><strong>Coverage Level:</strong>&nbsp;{item.coveragelevel ? item.coveragelevel[0] : ''}</p> : ''}
                                                        {item.servicetype ? <p><strong>Service Type:</strong>&nbsp;{item.servicetype ? item.servicetype[0] : ''}</p> : ''}
                                                        {item.servicetypecode ? <p><strong>Service Type Code:</strong>&nbsp;{item.servicetypecode ? item.servicetypecode[0] : ''}</p> : ''}
                                                        {item.insurancetype ? <p><strong>Insurance Type:</strong>&nbsp;{item.insurancetype ? item.insurancetype[0] : ''}</p> : ''}
                                                        {item.insurancetypecode ? <p><strong>Insurance Type Code:</strong>&nbsp;{item.insurancetypecode ? item.insurancetypecode[0] : ''}</p> : ''}
                                                        {item.plancoveragedescription ? <p><strong>Plan Coverage Description:</strong>&nbsp;{item.plancoveragedescription ? item.plancoveragedescription[0] : ''}</p> : ''}
                                                        <p><strong>Date of Birth:</strong>&nbsp;{elStatus[0]?.eligibilityData?.dependent[0]['date-of-birth'] ? elStatus[0]?.eligibilityData?.dependent[0]['date-of-birth'][0] : ''}</p>
                                                        <p><strong>Date Qualifier:</strong>&nbsp;{elStatus[0]?.eligibilityData?.dependent[0].date[0].datequalifier ? elStatus[0]?.eligibilityData?.dependent[0].date[0].datequalifier[0] : ''}</p>
                                                        <p><strong>Date of service:</strong>&nbsp;{elStatus[0]?.eligibilityData?.dependent[0].date[0]['date-of-service'] ? elStatus[0]?.eligibilityData?.dependent[0].date[0]['date-of-service'][0] : ''}</p>
                                                        {item.message ? <p><strong>Message:</strong>&nbsp;{item.message ? item.message : ''}</p> : ''}

                                                        {idx !== length - 1 ? <hr /> : <></>}
                                                    </>)
                                            })
                                            }
                                            {elStatus.eligibilitySuccess === false && elStatus[0]?.eligibilityData?.subscriber && elStatus[0]?.eligibilityData?.subscriber[0].benefit.map((item, idx) => {
                                                let length = elStatus[0]?.eligibilityData?.subscriber[0].benefit.length;
                                                // console.log(length);
                                                return (
                                                    <>
                                                        <p><strong>Info:</strong>&nbsp;{item.info ? item.info[0] : ''}</p>
                                                        {item.benefitentity ? <p><strong>Benefit Related Entity Id:</strong>&nbsp;{item.benefitentity[0].benefit_related_entity_id[0]}</p> : ''}
                                                        {item.benefitentity ? <p><strong>Entity Code:</strong>&nbsp;{item.benefitentity[0].entitycode[0]}</p> : ''}
                                                        {item.coveragelevel ? <p><strong>Coverage Level:</strong>&nbsp;{item.coveragelevel ? item.coveragelevel[0] : ''}</p> : ''}
                                                        {item.servicetype ? <p><strong>Service Type:</strong>&nbsp;{item.servicetype ? item.servicetype[0] : ''}</p> : ''}
                                                        {item.servicetypecode ? <p><strong>Service Type Code:</strong>&nbsp;{item.servicetypecode ? item.servicetypecode[0] : ''}</p> : ''}
                                                        {item.insurancetype ? <p><strong>Insurance Type:</strong>&nbsp;{item.insurancetype ? item.insurancetype[0] : ''}</p> : ''}
                                                        {item.insurancetypecode ? <p><strong>Insurance Type Code:</strong>&nbsp;{item.insurancetypecode ? item.insurancetypecode[0] : ''}</p> : ''}
                                                        {item.plancoveragedescription ? <p><strong>Plan Coverage Description:</strong>&nbsp;{item.plancoveragedescription ? item.plancoveragedescription[0] : ''}</p> : ''}
                                                        {elStatus[0]?.eligibilityData?.subscriber[0]['date-of-birth'] ? <p><strong>Date of Birth:</strong>&nbsp;{elStatus[0]?.eligibilityData?.subscriber[0]['date-of-birth'][0]}</p> : ''}
                                                        {elStatus[0]?.eligibilityData?.subscriber[0].date ? <p><strong>Date Qualifier:</strong>&nbsp;{elStatus[0]?.eligibilityData?.subscriber[0].date[0].datequalifier[0]}</p> : ''}
                                                        {elStatus[0]?.eligibilityData?.subscriber[0].date ? <p><strong>Date of service:</strong>&nbsp;{elStatus[0]?.eligibilityData?.subscriber[0].date[0]['date-of-service'][0]}</p> : ''}
                                                        {item.message ? <p><strong>Message:</strong>&nbsp;{item.message ? item.message : ''}</p> : ''}

                                                        {idx !== length - 1 ? <hr /> : <></>}
                                                    </>)
                                            })
                                            }
                                            {elStatus.eligibilitySuccess === false && (elStatus[0]?.eligibilityData?.subscriber || elStatus[0]?.eligibilityData?.dependent) &&
                                                <>
                                                    {elStatus[0]?.eligibilityData?.subscriber[0].rejection ? <p><strong>Rejection Reason:</strong>&nbsp;{elStatus[0]?.eligibilityData?.subscriber[0].rejection[0].rejectreason}</p> : ''}
                                                    {elStatus[0]?.eligibilityData?.subscriber[0].rejection ? <p><strong>Follow Up Action:</strong>&nbsp;{elStatus[0]?.eligibilityData?.subscriber[0].rejection[0].followupaction}</p> : ''}
                                                </>
                                            }
                                            {/* {!status.eligibilityData.dependent && status.eligibilityData.subscriber ? status.eligibilityData.subscriber[0].benefit.map((item, idx) => {
                                                let length = status.eligibilityData.subscriber[0].benefit.length;
                                                console.log(length);
                                                return(
                                                <>
                                                    <p><strong>Info:</strong>&nbsp;{item.info ? item.info[0] : ''}</p>
                                                    {item.coveragelevel ? <p><strong>Coverage Level:</strong>&nbsp;{item.coveragelevel ? item.coveragelevel[0] : ''}</p> : ''}
                                                    {item.servicetype ? <p><strong>Service Type:</strong>&nbsp;{item.servicetype ? item.servicetype[0] : ''}</p> : ''}
                                                    {item.servicetypecode ? <p><strong>Service Type Code:</strong>&nbsp;{item.servicetypecode ? item.servicetypecode[0] : ''}</p> : ''}
                                                    {item.insurancetype ? <p><strong>Insurance Type:</strong>&nbsp;{item.insurancetype ? item.insurancetype[0] : ''}</p> : ''}
                                                    {item.insurancetypecode ? <p><strong>Insurance Type Code:</strong>&nbsp;{item.insurancetypecode ? item.insurancetypecode[0] : ''}</p> : ''}
                                                    {item.plancoveragedescription ? <p><strong>Plan Coverage Description:</strong>&nbsp;{item.plancoveragedescription ? item.plancoveragedescription[0] : ''}</p> : ''}
                                                    <p><strong>Date of Birth:</strong>&nbsp;{status.eligibilityData.subscriber[0]['date-of-birth'] ? status.eligibilityData.subscriber[0]['date-of-birth'][0] : ''}</p>
                                                    <p><strong>Date Qualifier:</strong>&nbsp;{status.eligibilityData.subscriber[0].date[0].datequalifier ? status.eligibilityData.subscriber[0].date[0].datequalifier[0] : ''
                                                     + ', ' + status.eligibilityData.subscriber[0].date[1].datequalifier ? status.eligibilityData.subscriber[0].date[1].datequalifier[0] : ''}</p>
                                                    <p><strong>Date of service:</strong>&nbsp;{status.eligibilityData.subscriber[0].date[0]['date-of-service'] ? status.eligibilityData.subscriber[0].date[0]['date-of-service'][0] : ''
                                                     + '-' + status.eligibilityData.subscriber[0].date[1]['date-of-service'] ? status.eligibilityData.subscriber[0].date[1]['date-of-service'][0] : ''}</p>
                                                    {item.message ? <p><strong>Message:</strong>&nbsp;{item.message ? item.message : ''}</p> : ''}

                                                    {idx !== length-1 ? <hr /> : <></>}
                                                </>
                                            )}) : status.eligibilityData.dependent[0].benefit.map((item, idx) => {
                                                let length = status.eligibilityData.dependent[0].benefit.length;
                                                console.log(length);
                                                return(
                                                <>
                                                    <p><strong>Info:</strong>&nbsp;{item.info ? item.info[0] : ''}</p>
                                                    {item.coveragelevel ? <p><strong>Coverage Level:</strong>&nbsp;{item.coveragelevel ? item.coveragelevel[0] : ''}</p> : ''}
                                                    {item.servicetype ? <p><strong>Service Type:</strong>&nbsp;{item.servicetype ? item.servicetype[0] : ''}</p> : ''}
                                                    {item.servicetypecode ? <p><strong>Service Type Code:</strong>&nbsp;{item.servicetypecode ? item.servicetypecode[0] : ''}</p> : ''}
                                                    {item.insurancetype ? <p><strong>Insurance Type:</strong>&nbsp;{item.insurancetype ? item.insurancetype[0] : ''}</p> : ''}
                                                    {item.insurancetypecode ? <p><strong>Insurance Type Code:</strong>&nbsp;{item.insurancetypecode ? item.insurancetypecode[0] : ''}</p> : ''}
                                                    {item.plancoveragedescription ? <p><strong>Plan Coverage Description:</strong>&nbsp;{item.plancoveragedescription ? item.plancoveragedescription[0] : ''}</p> : ''}
                                                    <p><strong>Date of Birth:</strong>&nbsp;{status.eligibilityData.dependent[0]['date-of-birth'] ? status.eligibilityData.dependent[0]['date-of-birth'][0] : ''}</p>
                                                    <p><strong>Date Qualifier:</strong>&nbsp;{status.eligibilityData.dependent[0].date[0].datequalifier ? status.eligibilityData.dependent[0].date[0].datequalifier[0] : ''}</p>
                                                    <p><strong>Date of service:</strong>&nbsp;{status.eligibilityData.dependent[0].date[0]['date-of-service'] ? status.eligibilityData.dependent[0].date[0]['date-of-service'][0] : ''}</p>
                                                    {item.message ? <p><strong>Message:</strong>&nbsp;{item.message ? item.message : ''}</p> : ''}

                                                    {idx !== length-1 ? <hr /> : <></>}
                                                </>)})} */}
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        }
                    </div>
                </div>

                <NoViolationDatatable data={state.allChargeData} />
                <Dialog
                    className='issue-popup-main'
                    fullWidth={'lg'}
                    maxWidth={'lg'}
                    open={this.state.bugModal}
                    onClose={() => this.handleBugModal(false)}
                >
                    <DialogTitle>
                        <span className='reportIssue'>Report Issue</span>
                        <div style={{ borderBottom: '1px solid #5CB02C' }}></div>
                    </DialogTitle>
                    <DialogContent>
                        <Card className='issueCard'>
                            <CardContent className='issueContent'>
                                <div className='mx-2 row'>
                                    <div className='col-12 col-lg-12 col-sm-12'>
                                        <label htmlFor='issue'><strong style={{ fontSize: '18px' }}>Please select the issue:</strong></label>
                                        <select className='issue_select' name='issue' style={{ backgroundColor: 'rgb(245 242 242)' }} value={this.state.selectValue} onChange={this.handleSelectOption} disabled>
                                            <option value="claim">Claim didn't violate</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>
                                    {this.state.isOther === false ? <div className='mt-3 col-12 col-lg-12 col-sm-12'>
                                        <label htmlFor='rules'><strong style={{ fontSize: '18px' }}>Which rule(s) should have violated?</strong></label>
                                        <select className='issue_select' style={{ backgroundColor: 'rgb(245 242 242)' }} value={this.state.bugRuleId} disabled>
                                            <option value={this.state.bugRuleId} disabled>{this.state.bugRuleName}</option>
                                            {/* {this.state.rules?.map((rule) => (
                                                <option key={rule.ruleId} value={rule.ruleId}>{rule.ruleName}</option>
                                            ))} */}
                                        </select>
                                    </div> : <></>}
                                    <div className='mt-3 col-12 col-lg-12 col-sm-12'>
                                        <label htmlFor='info'><strong style={{ fontSize: '18px' }}>Please give us more information:</strong></label>
                                        <textarea onChange={e => this.setState({ errorDescription: e.target.value })} className='px-2 py-2 free_text' placeholder='Type here...'></textarea>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <footer>
                        <div className='buttons'>
                            <button className='activity_close_log'
                                onClick={this.handleBugModal}>
                                <span className='font-face-futura'>Close</span>
                            </button>
                            <button onClick={e => this.falseBugReport()} className='activity_submit_log'>
                                <span className='font-face-futura'>Submit</span>
                            </button>
                        </div>
                    </footer>
                </Dialog>
            </section>
        )
    }
}

export default InspectionNoViolation;